.button {
  @apply transition duration-300 ease-in-out border-0 p-3;
  background-color: theme("colors.blue.500")!important;
}

.primary {
  @apply bg-blue-500 text-white px-4 py-2 text-base w-full max-w-xs rounded mb-2;
}

.secondary {
  @apply bg-white text-black px-4 py-2 text-base w-full max-w-xs rounded mb-2;
}

.button:hover:not([disabled]) {
  @apply scale-105;
}
.button:active:not([disabled]) {
  @apply scale-110;
}
.button[disabled] {
  @apply opacity-50 cursor-not-allowed;
}
